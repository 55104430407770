import React from 'react';
import { arrayOf, string, func } from 'prop-types';
import classNames from 'classnames';

const IntegrationsBuilder = ({ integrations, component_id: componentId, className, runCatchErrorBoundary }) => {
  try {
    const namespace = 'mshops-3rd-party-integrations';
    const sectionId = classNames({
      [`${namespace}__sidebar`]: componentId === 'third_party_first_section',
      [`${namespace}__main`]: componentId === 'third_party_second_section',
    });

    return (
      <>
        {integrations?.length > 0 && (
          <div className={className}>
            <div id={sectionId}>
              {integrations.map(integration => (
                <div key={integration} id={`${integration}-container`} />
              ))}
            </div>
          </div>
        )}
      </>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

IntegrationsBuilder.propTypes = {
  integrations: arrayOf(string),
  component_id: string,
  className: string,
  runCatchErrorBoundary: func,
};

IntegrationsBuilder.defaultProps = {
  integrations: [],
  className: '',
  runCatchErrorBoundary: () => {},
};

export default IntegrationsBuilder;
